import colors from "./colors";
import buttons from "./buttons";
import variants from "./variants";
import forms from "./forms";
import { theme as themeSettings } from "../cms/data/settings";

const theme = {
  colors: colors,
  forms: forms,
  fonts: {
    body: themeSettings.font.fontname + ", sans-serif",
    heading: themeSettings.fontheads.fontname + ", sans-serif",
    monospace: "Menlo, monospace"
  },
  fontSizes: [12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 26, 28, 34, 36, 38, 40, 44, 50],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
    semiBold: 600
  },
  lineHeights: {
    body: 1.5,
    heading: "normal"
  },
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 110, 120, 140, 150, 200],
  sizes: {
    avatar: 48
  },
  radii: {
    square: 0,
    default: "4px",
    large: "6px",
    circle: 99999
  },
  shadows: {
    card: "0 0 20px 0 rgba(0, 0, 0, 0.1)",
    header: "0 2px 10px 0 rgba(0, 0, 0, 0.3)",
    headerLight: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
    buttonGradiant: "0 30px 25px -20px #eb7600",
    blockqueote: "0px 30px 25px -20px rgba(235, 118, 0, 0.5)"
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
    },
    h1: {
      mb: 4,
      fontSize: 15,
      fontWeight: "bold",
      fontStyle: "italic"
    },
    h2: {
      mb: 6,
      fontSize: 13,
      textTransform: "uppercase",
      textAlign: "center",
      "&:after": {
        content: "''",
        height: "5px",
        width: "35px",
        bg: "primary",
        display: "block",
        mx: "auto",
        mt: 2,
      }
    },
    h3: {
      fontSize: 10,
      mb: 2,
      textTransform: "uppercase",
    },
    display: {
      fontWeight: "bold",
      fontSize: [8,8,9]
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      fontSize: "inherit"
    },
    blockTitle: {
      mt:0,
      mb: [5,5,10],
      textTransform: "inherit",
      fontWeight: "bold",
      fontSize: [8,8,11],
      "&:after": {
        content: "''",
        display: "block",
        width: "50px",
        height: "5px",
        bg: "primary",
        mt:[1,2,3]
      }
    }
  },
  variants: variants,
  buttons: buttons,
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body"
    }
  },
  breakpoints: Object.values(themeSettings.breakpoints),
  gutter: 30
};

export default theme;
