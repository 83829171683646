import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import { withTheme } from "styled-components";
import Container from "./UI/Container";
import Link from "./UI/Link";
import { useStaticQuery, graphql } from "gatsby";
import Section from "./UI/Section";
import CustomImage from "./UI/CustomImage";
import Fontello from "./UI/Fontello";

const FooterRaw = ({ theme, menusSettings, footerSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo";
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };

  if (!menusSettings.activeFooter) {
    return <></>;
  }
  return (
    <Section bg="footer" color="#fff" py={[0,0,0]}>
      <Box
      pt={6}
      pb={4}
      sx={{
        borderWidth: "2px 0 0 0",
        borderStyle: "solid",
        borderColor: "primary",
        "a:hover": {
          color: "primary"
        }
      }}>
        <Container>
          <Flex
          justifyContent={["flex-start","flex-start","space-between"]}
          flexDirection={["column","column","row"]}
          alignItems="center"
          pb={6}>
            <Flex>
              <Box mr={[0,0,6]}><CustomImage img={footerSettings.logoFooter} height="auto" width="110px" /></Box>
              <Box><CustomImage img={footerSettings.logoFooter2} height="auto" width="90px" /></Box>           
            </Flex>
            <Box pt={[6,6,0]}>
              <a href={footerSettings.socials.facebook} target="_blank" >
                <Flex alignItems="center">
                  Suivez-nous sur Facebook<Box fontSize={9} ml={2}><Fontello icon="facebook" /></Box>
                </Flex>
              </a>
            </Box>
          </Flex>

          <Box
          display={["block","block","block","flex"]}
          justifyContent={["flex-start","flex-start","space-between"]}
          alignItems="flex-end">
            <Box
            textAlign={["center","center","center","left"]}
            fontWeight="bold"
            fontSize={8}
            color="primary"
            sx={{
              fontStyle:"italic"
            }}>{footerSettings.sloganFooter}</Box>
            <Box pt={[6,6,6,0]}>
              <Flex
              justifyContent={["center","center","center","flex-end"]}
              flexDirection={["column","row"]}
              alignItems="center">
                {menusSettings.footermenu.map((item, i) => (
                  <Box mr={[0,6,6]} mb={[3,0,0]}>
                    <Link
                      activeClassName="active"
                      href={item.path}
                      external={item.external}
                      key={"menu-" + i}
                    >
                      <Button variant="ninja">
                        <Text fontSize={2}>{item.title}</Text>
                      </Button>
                    </Link>
                  </Box>
                ))}
                <Box pt={[6, 0, 0]} fontSize={2}>
                  <>
                    Réalisation :{" "}
                    <a href="https://la-fabrik.nc">
                      <strong>La Fabrik</strong>
                    </a>
                  </>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Container>
      </Box>      
    </Section>
  );
};

FooterRaw.propTypes = {
  logoAlt: PropTypes.string,
  footerSettings: PropTypes.shape({
    addressFooter: PropTypes.any,
    logoFooter: PropTypes.any,
    phoneFooter: PropTypes.any
  }),
  menusSettings: PropTypes.shape({
    footermenu: PropTypes.array,
    footermenuen: PropTypes.array,
    activeFooter: PropTypes.bool
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Footer = ({ theme }) => {
  const { allMarkdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { title: { in: ["menus", "footer"] } } }) {
          nodes {
            frontmatter {
              title
              activeFooter
              footermenu {
                external
                offset
                path
                title
              }
              logoFooter {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 300, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              logoFooter2 {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 300, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              addressFooter
              phoneFooter
              mailFooter
              sloganFooter
              socials {
                facebook
              }
            }
          }
        }
      }
    `
  );

  const menusSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "menus") || {};
  const menusSettings = menusSettingsFrontmatter.frontmatter
    ? menusSettingsFrontmatter.frontmatter
    : {};
  const footerSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "footer") || {};
  const footerSettings = footerSettingsFrontmatter.frontmatter
    ? footerSettingsFrontmatter.frontmatter
    : {};
  return (
    <FooterRaw
      theme={theme}
      menusSettings={menusSettings}
      footerSettings={footerSettings}
      logoAlt={site.siteMetadata.title}
    />
  );
};

Footer.propTypes = {
  theme: PropTypes.any
};

export { FooterRaw };
export default withTheme(Footer);
