const baseItem = {
  color: "text",
  borderColor: "gray",
  borderWidth: "1px",
  borderRadius: "0",
  "::placeholder": {
    color: "gray"
  }
};

const forms = {
  input: baseItem,
  select: baseItem,
  textarea: baseItem,
  text: { color: "text" },
  label: { 
    pb: 0,
    fontSize: 3,
    fontWeight: "normal"
  },
  radio: { mr: 2 },
  checkbox: { mr: 2 }
};

export default forms;
