import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import { withTheme } from "styled-components";
import Container from "./UI/Container";
import Fontello from "./UI/Fontello";
import Link from "./UI/Link";
import { useStaticQuery, graphql } from "gatsby";
import CustomImage from "./UI/CustomImage";
import loadable from "@loadable/component";

const Sticky = loadable(() => import("react-stickynode"));
const Drawer = loadable(() => import("./Drawer"));

const NavbarRaw = ({ theme, menusSettings, footerSettings, logoAlt, isHome }) => {
  logoAlt = logoAlt || "Logo";
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };
  if (!menusSettings.activeTopMenu) {
    return <></>;
  }
  return (
    <Sticky
    top={0}
    innerZ={5}
    onStateChange={status => setStiked(status.status > 0)}>
      <Box
      variant="navbar"
      className={stiked ? "sticky" : "sticky-off"}
      sx={{
        position: "fixed",
        boxShadow: stiked ? "header" : "none",
        "&.sticky-off": {
          ".logo": {
            display: isHome ? "block" : "none",
          }
        }
      }}
      bg={stiked ? "white" : ["white","white","transparent"]}
      py={[1,1,2]}
      >
        <Container>
          <Flex alignItems={stiked ? "center" : ["center","center","flex-start"]}>
            <Box className="logo">
              <Link href="/">
                <CustomImage
                  width={stiked ? "50px" : ["50px","65px","170px"]}
                  height="auto"
                  img={stiked ? menusSettings.logo : menusSettings.logo2}
                  alt={logoAlt}
                />
              </Link>
            </Box>
            <Box mx="auto" />
            <Box alignItems="center" display={["none", "none", "flex"]} pt={stiked ? 0 : [0,0,2]}>
              <a href="tel=+687284208">
                <Flex alignItems="center" color={stiked ? "text" : "white"}>
                  <Box fontSize={9} mr={2} color={stiked ? "primary" : "white"}><Fontello icon="phone" /></Box>28 42 08
                </Flex>
              </a>
              <Box ml={10} sx={{"a":{display:"block",cursor:"pointer"}}}>
                <Link href="#adresse">
                  <Flex alignItems="center" color={stiked ? "text" : "white"}>
                    <Box fontSize={9} mr={2} color={stiked ? "primary" : "white"}><Fontello icon="poi" /></Box>Nous trouver
                  </Flex>
                </Link>
              </Box>
            </Box>
            <Drawer
              closeButton={
                <Box textAlign="right">
                  <Button variant="ninja" p={3} fontSize={14} color="primary">
                    <Fontello icon="close" />
                  </Button>
                </Box>
              }
              placement="right"
              drawerHandler={
                <Button variant="ninja" ml={10} display={"block"} color={stiked ? "primary" : ["primary","primary","white"]} fontSize={14}>
                  <Fontello icon="menu" />
                </Button>
              }
              open={open}
              toggleHandler={toggleHandler}
            >
              <Box width={["100vw", "100vw", "400px"]} bg="background" px={6} sx={{"a:hover":{color:"primary"}}}>
                {menusSettings.mainmenu
                  .filter(item => item.inSideBar)
                  .map((item, i) => (
                    <Link href={item.path} external={item.external} onClick={toggleHandler} key={i}>
                      <Button
                        variant="ninja"
                        px={3}
                        py={3}
                        width="100%"
                        textAlign="center"
                        onClick={toggleHandler}
                      >
                        <Text pl={[0, 0, 3]}>{item.title}</Text>
                      </Button>
                    </Link>
                  ))}
                  <Box px={6}>
                    <Box py={6} my={6}
                    sx={{
                      borderColor: "gray",
                      borderWidth: "1px 0",
                      borderStyle: "solid"
                    }}>
                      <a href={footerSettings.socials.facebook} target="_blank" >
                        <Flex alignItems="center" justifyContent="center">
                          Suivez-nous sur Facebook<Box fontSize={9} ml={2} color="primary"><Fontello icon="facebook" /></Box>
                        </Flex>
                      </a>
                    </Box>
                  </Box>
                  <Flex
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center">
                    {menusSettings.footermenu.map((item, i) => (
                      <Box mb={3}>
                        <Link
                          activeClassName="active"
                          href={item.path}
                          external={item.external}
                          key={"menu-" + i}
                        >
                          <Button variant="ninja">
                            <Text fontSize={2}>{item.title}</Text>
                          </Button>
                        </Link>
                      </Box>
                    ))}
                    <Box pt={[6, 0, 0]} fontSize={2}>
                      <>
                        Réalisation :{" "}
                        <a href="https://la-fabrik.nc">
                          <strong>La Fabrik</strong>
                        </a>
                      </>
                    </Box>
                  </Flex>
              </Box>
            </Drawer>
          </Flex>
        </Container>
      </Box>
    </Sticky>
  );
};

NavbarRaw.propTypes = {
  logoAlt: PropTypes.string,
  menusSettings: PropTypes.shape({
    logo: PropTypes.any,
    logo2: PropTypes.any,
    mainmenu: PropTypes.any,
    activeTopMenu: PropTypes.bool
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Navbar = ({ theme, isHome }) => {
  const { allMarkdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allMarkdownRemark(filter: { frontmatter: { title: { in: ["menus", "footer"] } } }) {
          nodes {
            frontmatter {
              title
              activeTopMenu
              logo2 {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 170, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              logo {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 170, quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
              mainmenu {
                external
                inNavBar
                inSideBar
                offset
                path
                title
              }
              activeFooter
              footermenu {
                external
                offset
                path
                title
              }
              socials {
                facebook
              }
            }
          }
        }
      }
    `
  );
  const menusSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "menus") || {};
  const menusSettings = menusSettingsFrontmatter.frontmatter
    ? menusSettingsFrontmatter.frontmatter
    : {};
  const footerSettingsFrontmatter =
    allMarkdownRemark.nodes.find(element => element.frontmatter.title === "footer") || {};
  const footerSettings = footerSettingsFrontmatter.frontmatter
    ? footerSettingsFrontmatter.frontmatter
    : {};
  return (
    <NavbarRaw theme={theme} menusSettings={menusSettings} footerSettings={footerSettings} logoAlt={site.siteMetadata.title} isHome={isHome} />
  );
};

Navbar.propTypes = {
  theme: PropTypes.any
};

export { NavbarRaw };
export default withTheme(Navbar);
