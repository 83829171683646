import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import PropTypes from "prop-types";

const Page = ({ children, title, description, thumbnail, isHome }) => {
  thumbnail = thumbnail || false;
  isHome = isHome==false ? false : true;
  return (
    <Layout isHome={isHome}>
      <SEO title={title} description={description} thumbnail={thumbnail} />
      {children}
    </Layout>
  );
};
Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.string
};
export default Page;
