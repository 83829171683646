import { rgba } from "polished";
import colors from "./colors";
import icons from "./icons";
const variants = {
  icons: icons,
  avatar: {
    width: "avatar",
    height: "avatar",
    borderRadius: "circle"
  },
  badges: {
    display: "inline-block",
    px: 2,
    py: 1,
    borderRadius: 9999,
    fontSize: 0
  },
  badge: {
    primary: {
      variant: "variants.badges",
      color: "white",
      bg: "primary"
    }
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    fontWeight: "inherit"
  },
  linktext: {
    color: "inherit",
    textDecoration: "inherit"
  },
  darkBg: {
    backgroundColor: "primary",
    color: "background"
  },
  bgImg: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  squareBox: {
    position: "relative",
    "&:after": {
      content: "''",
      display: "block",
      paddingBottom: "100%"
    }
  },
  nav: {
    fontSize: 1,
    fontWeight: "bold",
    display: "inline-block",
    p: 2,
    color: "inherit",
    textDecoration: "none",
    ":hover,:focus,.active": {
      color: "primary"
    }
  },
  cookiesBanner: {
    position: "fixed",
    bottom: "0",
    left: 0,
    right: 0,
    zIndex: 99,
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)"
  },
  formItemError: {
    "input,textarea,select": {
      borderColor: colors.danger
    }
  },
  navbar: {
    width: "100%",
    height: "auto",
    position: "inherit",
    zIndex: 10,
    "& > div": {
      position: "relative",
      zIndex: 2,
      ".logo-link": {
        display: "block",
        boxShadow: ["none","logo"],
        bg: "#fff",
        p: [0,3],
        position: ["relative","absolute"],
        top: ["inherit",0], 
        left: ["inherit",0]
      }
    }
  },
  container: {
    width: "100%",
    maxWidth: ["100%", "100%", "100%", "1260px"],
    m: "0 auto",
    flexbox: true,
    px: [2, 6, 6, 6]
  },
  containerNoGutter: {
    variant: "variants.container",
    px: [0,0,0,0]
  },
  containerFluid: {
    width: "100%",
    m: "0 auto",
    flexbox: true,
    px: [2, 6, 6, 6]
  },
  grid: {
    flexWrap: "wrap",
    mx: [-2, -3, -3]
  },
  gridItem: {
    px: [2, 3, 3]
  },
  section: {
    width: "100%",
    pt: [10, 10, 11],
    pb: [10, 10, 11]
  },  
  
  horizontalCard: { variant: "variants.card" },
  verticalCard: { variant: "variants.card" },
  card: {
    bg: "white",
    overflow: "hidden",
    position: "relative",
    transition: "all 300ms ease",
    boxShadow: "card",
  },
  cardContent: {
    p:4
  },
  overlayLink: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    zIndex: "10",
    "& > a": {
      display: "block",
      width: "100%",
      height: "100%",
      textIndent: "-99999px",
      overflow: "hidden"
    }
  }
};

export default variants;
